@tailwind base;
@tailwind utilities;
@tailwind components;

:root {
    --oxford-blue: 0, 33, 71;
}

.bg-custom-oxford {
    background-image: linear-gradient(to top, rgba(var(--oxford-blue), 0.99), rgba(var(--oxford-blue), 0.8)),
    url("/src/assets/oxford-background.jpg?as=webp&q=50");
    background-size: cover;
    background-position: center;
}

#navbar.inverted {
    background: linear-gradient(90deg, rgba(0, 33, 71, 1) 0%, rgba(25, 55, 89, 1) 100%);
    z-index: 9999;
}

#navbar.inverted .navbar-container {
    border-bottom-color: rgb(var(--oxford-blue));
}

.question-label.active {
    @apply bg-brand-500  text-white border-l-4 border-brand-300 transition-all;
}

.question-label.active .arrow {
    transform: translateX(10px);
}


#parent-banner:not(.hidden) ~ div {
    top: calc(3rem - 4px);
    margin-bottom: 3rem;
}

.question:not(.active) {
    display: none;
}

.timeline-fading-border {
    border-width: 3px;
    border-right: none !important;
    border-image: linear-gradient(
            to bottom,
            theme('colors.brand.50'),
            theme('colors.brand.200'),
            theme('colors.brand.300'),
            theme('colors.brand.400'),
            theme('colors.brand.500'),
            theme('colors.brand.600'),
            theme('colors.brand.700')
    ) 1 100%;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.tab:not(.active) .tab-border {
    display: none;
}

.tab:not(.active) {
    @apply bg-gray-100;
}

.toc a.active {
    @apply text-brand-500;
}

.toc a:not(.active):hover {
    @apply text-brand-500;
}